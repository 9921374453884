import React, { useRef } from "react";
import { dateUtil } from "../../utils/date-util";

const MonthSelector = () => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const defaultMonth = dateUtil.monthFromDate(date);

  return (
    <select
      defaultValue={defaultMonth}
      className="pl-3 pr-10 mt-1 border border-gray-300 rounded leading-6 focus:shadow-outline sm:text-sm sm:leading-5"
      id="month"
    >
      {dateUtil.months.map(month => (
        <option key={month.num} value={month.name}>
          {month.name}
        </option>
      ))}
    </select>
  );
};

const YearSelector = () => {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = currentYear - 10; i <= currentYear; i++) {
    years.push(i);
  }

  return (
    <select
      defaultValue={currentYear}
      id="year"
      className="pl-3 pr-10 mt-1 border border-gray-300 rounded leading-6 focus:shadow-outline sm:text-sm sm:leading-5"
    >
      {years.map(year => (
        <option key={year} value={year}>
          {year}
        </option>
      ))}
    </select>
  );
};

interface FileSubmitProps {
  onSubmit: (files: Map<string, File | undefined | null>, date: string) => any;
}
export function FileSubmit(props: FileSubmitProps) {
  const ahaCash = useRef<HTMLInputElement>(null);
  const ahaChase = useRef<HTMLInputElement>(null);
  const ahaMoneris = useRef<HTMLInputElement>(null);
  const ahaRbc = useRef<HTMLInputElement>(null);
  const graceChase = useRef<HTMLInputElement>(null);
  const graceMoneris = useRef<HTMLInputElement>(null);
  const graceRbc = useRef<HTMLInputElement>(null);
  const graceClover = useRef<HTMLInputElement>(null);
  const hscCash = useRef<HTMLInputElement>(null);
  const hscChase = useRef<HTMLInputElement>(null);
  const hscMoneris = useRef<HTMLInputElement>(null);
  const hscRbc = useRef<HTMLInputElement>(null);
  const hscClover = useRef<HTMLInputElement>(null);
  // const prhcChase = useRef<HTMLInputElement>(null);
  // const prhcRbc = useRef<HTMLInputElement>(null);

  function getFormattedDate() {
    const monthSelector = document.getElementById("month") as HTMLSelectElement;
    const yearSelector = document.getElementById("year") as HTMLSelectElement;
    const month = monthSelector.value;
    const year = yearSelector.value;
    return new Date(`${month} 01 ${year}`).toLocaleString();
  }

  function handleSubmit(e: any) {
    e.preventDefault();

    const date = getFormattedDate();
    const ahaCashFile = ahaCash.current?.files?.item(0);
    const ahaChaseFile = ahaChase.current?.files?.item(0);
    const ahaMonerisFile = ahaMoneris.current?.files?.item(0);
    const ahaRbcFile = ahaRbc.current?.files?.item(0);
    const graceChaseFile = graceChase.current?.files?.item(0);
    const graceMonerisFile = graceMoneris.current?.files?.item(0);
    const graceRbcFile = graceRbc.current?.files?.item(0);
    const graceCloverFile = graceClover.current?.files?.item(0);
    const hscCashFile = hscCash.current?.files?.item(0);
    const hscChaseFile = hscChase.current?.files?.item(0);
    const hscMonerisFile = hscMoneris.current?.files?.item(0);
    const hscRbcFile = hscRbc.current?.files?.item(0);
    const hscCloverFile = hscClover.current?.files?.item(0);
    // const prhcChaseFile = prhcChase.current?.files?.item(0);
    // const prhcRbcFile = prhcRbc.current?.files?.item(0);
    const map = new Map<string, File | undefined | null>();

    map.set("ahaCash", ahaCashFile);
    map.set("ahaChase", ahaChaseFile);
    map.set("ahaMoneris", ahaMonerisFile);
    map.set("ahaRbc", ahaRbcFile);
    map.set("graceChase", graceChaseFile);
    map.set("graceMoneris", graceMonerisFile);
    map.set("graceRbc", graceRbcFile);
    map.set("graceClover", graceCloverFile);
    map.set("hscCash", hscCashFile);
    map.set("hscChase", hscChaseFile);
    map.set("hscMoneris", hscMonerisFile);
    map.set("hscRbc", hscRbcFile);
    map.set("hscClover", hscCloverFile);
    // map.set("prhcChase", prhcChaseFile);
    // map.set("prhcRbc", prhcRbcFile);
    props.onSubmit(map, date);
  }

  return (
    <div
      className="container max-w-6xl bg-white
      flex flex-col w-full px-8 py-6 mx-auto border-2 rounded-lg shadow-md"
    >
      <h3 className="text-lg font-medium text-gray-900 leading-6">
        Upload Statement
      </h3>
      <div className="">
        <div className="flex items-center my-3">
          <h1 className="mr-5 text-sm text-gray-700">Statement Date</h1>
          <MonthSelector />
          <YearSelector />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-wrap">
            <FormSection label="AHA">
              {FileFormEntry("Cash", ahaCash)}
              {FileFormEntry("Chase", ahaChase)}
              {FileFormEntry("Moneris", ahaMoneris)}
              {FileFormEntry("RBC", ahaRbc)}
            </FormSection>
            <FormSection label="Grace">
              {FileFormEntry("Chase", graceChase)}
              {FileFormEntry("Clover", graceClover)}
              {FileFormEntry("Moneris", graceMoneris)}
              {FileFormEntry("RBC", graceRbc)}
            </FormSection>
            <FormSection label="HSC">
              {FileFormEntry("Cash", hscCash)}
              {FileFormEntry("Chase", hscChase)}
              {FileFormEntry("Clover", hscClover)}
              {FileFormEntry("Moneris", hscMoneris)}
              {FileFormEntry("RBC", hscRbc)}
            </FormSection>
            {/* <FormSection label="PRHC">
              {FileFormEntry("Chase", prhcChase)}
              {FileFormEntry("RBC", prhcRbc)}
            </FormSection> */}
          </div>
          <div className="flex">
            <SubmitBtn />
          </div>
        </form>
      </div>
    </div>
  );
}

function SubmitBtn() {
  return (
    <button
      className="px-2 py-1 mt-6 ml-auto text-sm font-semibold text-white bg-blue-500 shadow rounded-md hover:bg-blue-600"
      type="submit"
    >
      Upload
    </button>
  );
}

interface FormSectionProps {
  children?: any;
  label: string;
}
function FormSection(props: FormSectionProps) {
  const { children, label } = props;
  return (
    <div className="flex-1 max-w-lg">
      <h1 className="mb-3 font-semibold text-blue-600 text-md">{label}</h1>
      <div className="mb-5">{children}</div>
    </div>
  );
}

interface FormLabelProps {
  label: string;
}
function FormLabel(props: FormLabelProps) {
  return (
    <label className="text-sm text-gray-700 leading-5 md:w-56 sm:w-32">
      {props.label}
    </label>
  );
}

function FileFormEntry(label: string, ref: any) {
  return (
    <div className="mb-3 md:flex">
      <FormLabel label={label} />
      <input ref={ref} className="block w-64 text-xs" type="file"></input>
    </div>
  );
}
