import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { BlankScreen } from "./BlankScreen";
import { useStatements } from "../../services/statements";
import { useUpload } from "../../services/upload";
import { dateUtil } from "../../utils/date-util";

interface StatementDetailScreenProps {}
export function StatementDetailScreen(props: StatementDetailScreenProps) {
  const { id }: any = useParams();

  const {
    getStatementById,
    deleteStatementById,
    reprocessStatement
  } = useStatements();

  const [statement, setStatement] = useState(null) as any;
  const upload = useUpload();
  const { goBack } = useHistory();

  useEffect(() => {
    let isMounted = true;
    getStatementById(id).then(statement => {
      if (isMounted) setStatement(statement);
    });
    return () => {
      isMounted = false;
    };
  }, [getStatementById, id, setStatement]);

  const onFileClick = async (key: string) => {
    const data = await upload.getRedirectLink(key);
    const { url } = data;
    window.open(url);
  };

  async function statementDeleteHandler() {
    await deleteStatementById(id);
    goBack();
  }

  return (
    <BlankScreen>
      {statement && (
        <div className="container max-w-6xl flex flex-col w-full px-8 py-6 mx-auto bg-white border-2 rounded-lg shadow-md">
          <div className="flex">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              Statement Information
            </h3>
            <p className="ml-6 text-sm text-indigo-600 align-baseline leading-6">
              {dateUtil.monthFromDate(new Date(statement.date))}{" "}
              {new Date(statement.date).getUTCFullYear()}
            </p>
            <div className="ml-auto">
              <button
                className="px-2 py-1 ml-auto mr-3 text-sm font-semibold text-white bg-indigo-500 rounded hover:bg-indigo-600 hover:shadow"
                onClick={() => reprocessStatement(id)}
              >
                Reprocess
              </button>
              <button
                className="px-2 py-1 ml-auto mr-3 text-sm font-semibold text-white bg-blue-500 rounded hover:bg-blue-600 hover:shadow"
                onClick={() => onFileClick(statement["report"])}
              >
                Download Report
              </button>
              <button
                className="px-2 py-1 ml-auto text-sm font-semibold text-white bg-red-500 rounded hover:bg-red-600 hover:shadow"
                onClick={statementDeleteHandler}
              >
                Delete
              </button>
            </div>
          </div>
          <TimeEntry label="Uploaded on" date={statement.createdAt} />
          <div className="flex flex-wrap">
            <HospitalSection hospitalName="AHA">
              <FileEntry
                name="Cash"
                onClick={onFileClick}
                s3key={statement["ahaCash"]}
              />
              <FileEntry
                name="Chase"
                onClick={onFileClick}
                s3key={statement["ahaChase"]}
              />
              <FileEntry
                name="Moneris"
                onClick={onFileClick}
                s3key={statement["ahaMoneris"]}
              />
              <FileEntry
                name="RBC"
                onClick={onFileClick}
                s3key={statement["ahaRbc"]}
              />
            </HospitalSection>

            <HospitalSection hospitalName="Grace">
              <FileEntry
                name="Chase"
                onClick={onFileClick}
                s3key={statement["graceChase"]}
              />
              <FileEntry
                name="Moneris"
                onClick={onFileClick}
                s3key={statement["graceMoneris"]}
              />
              <FileEntry
                name="RBC"
                onClick={onFileClick}
                s3key={statement["graceRbc"]}
              />
              <FileEntry
                name="Clover"
                onClick={onFileClick}
                s3key={statement["graceClover"]}
              />
            </HospitalSection>

            <HospitalSection hospitalName="HSC">
              <FileEntry
                name="Cash"
                onClick={onFileClick}
                s3key={statement["hscCash"]}
              />
              <FileEntry
                name="Chase"
                onClick={onFileClick}
                s3key={statement["hscChase"]}
              />
              <FileEntry
                name="Moneris"
                onClick={onFileClick}
                s3key={statement["hscMoneris"]}
              />
              <FileEntry
                name="RBC"
                onClick={onFileClick}
                s3key={statement["hscRbc"]}
              />
              <FileEntry
                name="Clover"
                onClick={onFileClick}
                s3key={statement["hscClover"]}
              />
            </HospitalSection>

            {/* <HospitalSection hospitalName="PRHC">
              <FileEntry
                name="Chase"
                onClick={onFileClick}
                s3key={statement["prhcChase"]}
              />
              <FileEntry
                name="RBC"
                onClick={onFileClick}
                s3key={statement["prhcRbc"]}
              />
              <FileEntry
                name="Report"
                onClick={onFileClick}
                s3key={statement["report"] || "NA"}
              />
            </HospitalSection> */}
          </div>
          <StatusHistory statuses={statement.status || []} />
        </div>
      )}
    </BlankScreen>
  );
}

interface StatusEntryProps {
  statuses: [];
}
function StatusHistory(props: StatusEntryProps) {
  return (
    <div>
      <h1 className="mt-3 mb-2 text-gray-700 text-md">
        Status History{" "}
        <span className="text-xs font-normal italic">*Most recent first</span>
      </h1>
      <div className="overflow-y-auto border" style={{ maxHeight: "16rem" }}>
        {props.statuses.reverse().map((status, i) => {
          const isEven = i % 2 === 0;
          return (
            <p
              key={i}
              className={`text-sm w-full px-2 py-1 transition-colors hover:bg-blue-200 duration-100 ${status ===
                "error" && "text-red-700"} ${
                isEven ? "bg-blue-100" : "bg-white"
              }`}
            >
              {status}
            </p>
          );
        })}
      </div>
    </div>
  );
}

interface HospitalSectionProps {
  hospitalName: string;
  className?: string;
  children: any;
}
function HospitalSection(props: HospitalSectionProps) {
  return (
    <div className={props.className}>
      <div className="h-full my-3 pr-3">
        <h1 className="mb-3 font-semibold text-blue-600 text-md">
          {props.hospitalName}
        </h1>
        {props.children}
      </div>
    </div>
  );
}

interface FileEntryProps {
  name: string;
  s3key: string;
  onClick: (s3key: string) => any;
}
function FileEntry(props: FileEntryProps) {
  return (
    <div className="flex flex-wrap w-64 mb-3">
      <label className="flex-1 text-sm text-gray-700 leading-5">
        {props.name}:
      </label>
      <button
        onClick={
          props.s3key === "NA" ? () => {} : () => props.onClick(props.s3key)
        }
        className="flex-1 text-sm text-blue-600 hover:underline hover:text-blue-500"
      >
        Download
      </button>
    </div>
  );
}

interface TimeEntryProps {
  date: any;
  label: string;
}
function TimeEntry(props: TimeEntryProps) {
  return (
    <div className="max-w-sm py-3">
      <div className=" sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm text-gray-600 leading-5 ">{props.label}</dt>
        <dd className="mt-1 text-sm text-gray-600 leading-5 sm:mt-0 sm:col-span-2">
          <p>{dateUtil.dateFromMs(props.date)}</p>
        </dd>
      </div>
    </div>
  );
}
